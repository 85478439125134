'use strict'

let selectGroup = undefined,
  selectedTab = 0,
  selectedIndex = undefined

/**
 * List(Home)画面の初期化
 */
const initListSceen = () => {
  document.querySelector('#strength').style.display = 'none'
  document.querySelector('#club').style.display = 'none'
  document.querySelector('#like').style.display = 'none'
}
/**
 * HOMEのクラブカテゴリ
 */
const homeGroupListener = () => {
  const elm = document.documentElement;
  const bottom = elm.scrollHeight - elm.clientHeight;
  document.querySelector('#group-strength').addEventListener('click', () => {
    // reset body
    $('.category-body').each((_, body) => { $(body).empty() })
    document.querySelectorAll('.list-detail').forEach((elm) => {
      elm.style.display = 'none'
    })
    // scroll content
    //$(window).scrollTop($('#sticky-footer').position().top)
    //window.scrollTo({top: bottom, left: 0, behavior: 'smooth'});
    $("html,body").animate({ scrollTop: $('#sticky-footer').offset().top });
    //
    document.querySelector('#strength').style.display = 'block'
  })
  document.querySelector('#group-club').addEventListener('click', () => {
    // reset body
    $('.category-body').each((_, body) => { $(body).empty() })
    // Scroll Content 
    $(window).scrollTop($('#club').position().bottom)
    document.querySelectorAll('.list-detail').forEach((elm) => {
      elm.style.display = 'none'
    })
    // reset cate body
    $('.category-body-club').each((_, body) => { $(body).empty() })
    // scroll content
    //$(window).scrollTop($('#sticky-footer').position().top)
    //window.scrollTo({top: bottom, left: 0, behavior: 'smooth'});
    $("html,body").animate({ scrollTop: $('#sticky-footer').offset().top });
    document.querySelector('#club').style.display = 'block'
  })
  document.querySelector('#group-like').addEventListener('click', () => {
    // reset body
    $('.category-body').each((i, body) => { $(body).empty() })
    document.querySelectorAll('.list-detail').forEach((elm) => {
      elm.style.display = 'none'
    })
    // scroll content
    //$(window).scrollTop($('#sticky-footer').position().top)
    //window.scrollTo({top: bottom, left: 0, behavior: 'smooth'});
    $("html,body").animate({ scrollTop: $('#sticky-footer').offset().top });
    // reset cate body
    $('.category-body-like').each((_, body) => { $(body).empty() })
    document.querySelector('#like').style.display = 'block'
  })
}
/**
 * 部のタブ切替
 */
const clubTabSelect = () => {
  const clubSportsTab = document.querySelector('#club-sport')
  const clubCultureTab = document.querySelector('#club-culture')
  const clubSportsPanel = document.querySelector('#sports-club')
  const clubCulturePanel = document.querySelector('#culture-club')

  clubSportsTab.addEventListener('click', () => {
    const tabs = document.querySelectorAll('.club-tab-item')
    // reset body
    $('.category-body-club').each((i, body) => { $(body).empty() })

    // tab
    tabs.forEach((elm) => {
      elm.classList.remove('select')
    })
    clubSportsTab.classList.add('select')
    // panel
    document.querySelectorAll('.club-container').forEach((el) => {
      el.classList.remove('visible')
    })
    clubSportsPanel.classList.add('visible')
  })

  clubCultureTab.addEventListener('click', () => {
    const tabs = document.querySelectorAll('.club-tab-item')
    // reset body
    $('.category-body-club').each((_, body) => { $(body).empty() })

    // tab
    tabs.forEach((elm) => {
      elm.classList.remove('select')
    })
    clubCultureTab.classList.add('select')
    // panel
    document.querySelectorAll('.club-container').forEach((el) => {
      el.classList.remove('visible')
    })
    clubCulturePanel.classList.add('visible')
  })
}
/**
 * 同好会タブ
 */
const likeTabSelect = () => {
  const likeSportsTab = document.querySelector('#like-sport')
  const likeCultureTab = document.querySelector('#like-culture')
  const likeSports = document.querySelector('#sports-like')
  const likeCulture = document.querySelector('#culture-like')
  likeSports.style.display = 'block'
  likeCulture.style.display = 'none'

  likeSportsTab.addEventListener('click', () => {
    const tabs = document.querySelectorAll('.like-tab-item')
    // reset body
    $('.category-body-like').each((i, body) => { $(body).empty() })
    // tab
    tabs.forEach((elm) => {
      elm.classList.remove('select')
    })
    likeSportsTab.classList.add('select')
    // panel
    likeSports.style.display = 'block'
    likeCulture.style.display = 'none'
  })

  likeCultureTab.addEventListener('click', () => {
    const tabs = document.querySelectorAll('.like-tab-item')
    // reset body
    $('.category-body-like').each((i, body) => { $(body).empty() })
    // tab
    tabs.forEach((elm) => {
      elm.classList.remove('select')
    })
    likeCultureTab.classList.add('select')
    // panel
    likeSports.style.display = 'none'
    likeCulture.style.display = 'block'
  })
}

/**
 * 部のカテゴリ選択
 */
const clubCategoryEvent = () => {
  const clubCategory = $('.js--club-category')

  clubCategory.each((_, cate) => {
    cate.addEventListener('click', (e) => {

      $('.category-body-club').each((_, body) => { $(body).empty() })
      $('.ttl-category').each((_, ttl) => { $(ttl).parent().removeClass('selected') })
      $(cate).parent().addClass('selected')
      const $wrapper = $(cate).closest('.category-wrapper')

      // Clone items
      $wrapper.find('.clone-clubs').each((_, clone) => {
        if ($(clone).data("cat") != $(cate).data("cat")) return true

        $(clone).find('.cat-item').each((_, item) => {
          const row = $(item).data("row")

          $wrapper.find('.category-body-club').each((_, body) => {
            if (row == $(body).data("row")) {
              $(body).append($(item).clone())
              if ($(window).width() < 1024 && row > 1) {
                $(window).scrollTop($(body).position().top)
              }
            }
          })

        })
      })

    })
  })
}
/**
 * 同好会カテゴリ選択
 */
const likeCategoryEvent = () => {
  const wrapper = $('.like-container >.category-wrapper:visible')

  $('.js--like-category').each((_, cate) => {
    cate.addEventListener('click', (e) => {

      $('.category-body-like').each((_, body) => { $(body).empty() })
      $('.ttl-category').each((_, ttl) => { $(ttl).parent().removeClass('selected') })
      $(cate).parent().addClass('selected')
      const $wrapper = $(cate).closest('.category-wrapper')

      // Clone items
      $wrapper.find('.clone-likes').each((_, clone) => {
        if ($(clone).data("cat") != $(cate).data("cat")) return true

        $(clone).find('.cat-item').each((_, item) => {
          const row = $(item).data("row")

          $wrapper.find('.category-body-like').each((_, body) => {
            if (row == $(body).data("row")) {
              $(body).append($(item).clone())
              if ($(window).width() < 1024 && row > 1) {
                $(window).scrollTop($(body).position().top)
              }
            }
          })

        })
      })

    })
  })
}


/**
 * 画像ファイルチェック
 * 
 */
const imageUploadValidate = () => {
  const sizeLimit = 1024 * 1024 * 15 // 15MB
  const fileInput = document.getElementById('image-uploader')

  const handleFileSelect = () => {
    const files = fileInput.files
    console.log(files[0]);

    if (files[0].size > sizeLimit) {
      const err = document.getElementById("pic-error")
      err.style.display = "block"
      err.innerText = "ファイルサイズは15MB以下にしてください"
    }
  }

  fileInput.addEventListener('change', handleFileSelect);
}
const initEditScreen = () => {
  // Hide error message
  document.querySelectorAll('.form-error').forEach((err) => {
    err.style.display = 'none'
  })
}
/**
 * DOMContentLoaded
 */
document.addEventListener('DOMContentLoaded', () => {
  /**
   * Admin Screen
  */
  const menu = document.getElementById('menu-btn')
  const nav = document.getElementById('global')
  const ol = document.getElementById('overlay')
  const close = document.getElementById('close-btn')

  if (menu) {
    menu.addEventListener('click', () => {
      if (nav.classList.contains('open')) {
        nav.classList.remove('open')
        ol.style.display = 'none'
      } else {
        nav.classList.add('open')
        ol.style.display = 'block'
      }
    })
    ol.addEventListener('click', (e) => {
      nav.classList.remove('open')
      ol.style.display = 'none'
    })
    if (close) {
      close.addEventListener('click', (e) => {
        nav.classList.remove('open')
        ol.style.display = 'none'
      })
    }
  }
  /**
  * 年度切替
  * 
  * 
  */
  const cre = document.getElementById('create-btn')
  if (cre) {
    cre.addEventListener('click', (e) => {
      e.preventDefault()
      if (window.confirm('次年度提出フォームの作成を行います本当にいいですか？\n実行すると元には戻せません')) {
        document.querySelector('#create-form').submit()
        document.getElementById('create-btn').value = "作成中..."
      }
    })
  }

  const pub = document.getElementById('publish-btn')
  if (pub) {
    pub.addEventListener('click', (e) => {
      e.preventDefault()
      if (window.confirm('web一般公開を行います本当にいいですか？\n実行すると元には戻せません')) {
        document.querySelector('#publish-form').submit()
        document.getElementById('publish-btn').value = "切替中..."
      }
    })
  }
  // const csvForm = document.getElementById('csv-form')
  // if( csvForm ) {
  //   const uploadBtn = document.getElementById('btn-upload')
  //   uploadBtn.addEventListener('click', (e) => {
  //     e.preventDefault()
  //     let csvs = document.getElementById('csv-form')
  //     if (csvs.length > 0) {
  //       const csv = csvs.files[0]
  //     } else {
  //       console.log('no file')
  //     }
  //   })
  // }
  /**
   * スマホの団体テーブルの行順変更
   * 
   * club-circle-list table
   * 
   */
  if ($(window).width() < 1024) {
    console.info('1024px under')
    $('.table-club-body').each((_, e) => {
      let $cols = $(e).children()
      $cols.eq(0).before($cols.eq(3))
    })
    $('.table-manuscript-body').each((_, e) => {
      let $cols = $(e).children()
      $cols.eq(0).before($cols.eq(2))
    })
  }
  /**
   * 期間設定バリデーション
   * 
   * 
   */
  const period = document.getElementById('period-btn')
  if (period) {
    period.addEventListener('click', (e) => {
      e.preventDefault()

      const form = document.getElementById('period-form')
      const sd = document.getElementById('start-date').value
      const st = document.getElementById('start-time').value
      const ed = document.getElementById('end-date').value
      const et = document.getElementById('end-time').value
      let err = document.getElementById('period-error')
      let alert = document.querySelector('.alert')
      err.style.display = "none"
      if (alert) alert.style.display = 'none'

      //console.info('start date = ' + sd)
      //console.info('start time = ' + st)
      //console.info('end date = ' + ed)
      //console.info('end time = ' + et)
      const sdt = new Date(sd + 'T' + st)
      const edt = new Date(ed + 'T' + et)
      //console.info('start datetime = ' + sdt)
      //console.info('end datetime = ' + edt)

      if (sd && !st) {
        err.style.display = "block"
        err.textContent = "開始時間を設定してください。"
      } else if (!sd && st) {
        err.style.display = "block"
        err.textContent = "開始日を設定してください。"
      } else if (ed && !et) {
        err.style.display = "block"
        err.textContent = "終了時間を設定してください。"
      } else if (!ed && et) {
        err.style.display = "block"
        err.textContent = "終了日を設定してください。"
      } else if (sdt > edt) {
        err.style.display = "block"
        err.textContent = "終了日には開始日以降の日付を指定して下さい。"
      } else {
        form.submit()
      }
    })
  }
  /**
   * Frontend
   */
  if (document.querySelector('#front-home')) {
    initListSceen()
    homeGroupListener()
    clubTabSelect()
    likeTabSelect()
    clubCategoryEvent()
    likeCategoryEvent()
  }

  $('#back-top').on('click', () => {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  })
})
/**
 * 公開・非公開のAJAX
 * 
 * 
 */
$(function () {
  $('.publish-switcher').on('change', function () {
    const id = $(this).data('m_id')
    const status = $(this).val()
    const year = $(this).data('year')

    $.ajax({
      type: 'POST',
      url: '/admin/status_switch',
      data: { m_id: id, status: status, year: year },
      dataType: 'json'
    })
      .done(function (data) {
        console.log(data)
        window.location = data['redirect_link']
      })
  })
})


